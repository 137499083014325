import { ROUTE } from '@src/constants/route'
import { DeprecatePaths } from '@src/types/redirect'

export const DeprecateMap: DeprecatePaths = {
  '/apostle/apostleDetail/': ROUTE.MARKETPLACE_APOSTLE_DETAIL,
  '/battle-demo/download/': ROUTE.BATTLE_DEMO_GAME,
  '/battle/demo/download/': ROUTE.BATTLE_DEMO_GAME,
  '/battle/demo/leaderboard/': ROUTE.BATTLE_DEMO_SOLO_PVE,
  '/battle/demo/news/': ROUTE.BATTLE_DEMO_NEWS,
  '/battle/demo/pass/': ROUTE.BATTLE_DEMO_BATTLE_PASS,
  '/battle/demo/start/': ROUTE.BATTLE_DEMO_START_GAME,
  '/expedition/event/': ROUTE.EXPEDITION,
  '/expedition/': ROUTE.EXPEDITION,
  '/godiverse/godiverseDetail/': ROUTE.MARKETPLACE_GODIVERSE_DETAIL,
  '/godiverse/orbitalTrack/': ROUTE.GODIVERSE_MANAGE_ORBITAL_TRACK,
  '/godiverse/starAttachment/': ROUTE.GODIVERSE_MANAGE_ATTACHMENT,
  '/marketplace/apostle/activities/': ROUTE.MARKETPLACE_APOSTLE_ACTIVITY_LIST,
  '/marketplace/apostle/items/': ROUTE.MARKETPLACE_APOSTLE_LIST,
  '/marketplace/godiverses/activities/':
    ROUTE.MARKETPLACE_GODIVERSE_ACTIVITY_LIST,
  '/marketplace/godiverses/items/': ROUTE.MARKETPLACE_GODIVERSE_LIST,
  '/marketplace/orbiting/activities/':
    ROUTE.MARKETPLACE_ORBITAL_TRACK_ACTIVITY_LIST,
  '/marketplace/orbiting/items/': ROUTE.MARKETPLACE_ORBITAL_TRACK_LIST,
  '/marketplace/planets/activities/': ROUTE.MARKETPLACE_PLANET_ACTIVITY_LIST,
  '/marketplace/planets/items/': ROUTE.MARKETPLACE_PLANET_LIST,
  '/marketplace/stars/activities/': ROUTE.MARKETPLACE_STAR_ACTIVITY_LIST,
  '/marketplace/stars/items/': ROUTE.MARKETPLACE_STAR_LIST,
  '/other/inventory/apostles/': ROUTE.OTHER_INVENTORY_APOSTLE_LIST,
  '/other/inventory/godiverses/': ROUTE.OTHER_INVENTORY_GODIVERSE_LIST,
  '/other/inventory/planets/': ROUTE.OTHER_INVENTORY_PLANET_LIST,
  '/other/inventory/stars/': ROUTE.OTHER_INVENTORY_STAR_LIST,
  '/planet/planetDetail/': ROUTE.MARKETPLACE_PLANET_DETAIL,
  '/sanctuary/conjunction/': ROUTE.SANCTUARY_CELESTIAL_CONJUNCTION,
  '/sanctuary/apostle-attach/': ROUTE.SANCTUARY_CELESTIAL_BINDING,
  '/sanctuary/forge-apostle/': ROUTE.SANCTUARY_APOSTLE_FORGE,
  '/sanctuary/forge-apostle/ticket/': ROUTE.SANCTUARY_APOSTLE_FORGE_TICKET,
  '/star/starAttachAndTrack/': ROUTE.MARKETPLACE_STAR_MANAGE,
  '/star/starDetail/': ROUTE.MARKETPLACE_STAR_DETAIL,
  '/user/adeptus/invite/': ROUTE.ADEPTUS_PROGRAM,
  '/user/favorite/apostles/': ROUTE.FAVORITE_APOSTLE_LIST,
  '/user/favorite/godiverses/': ROUTE.FAVORITE_GODIVERSE_LIST,
  '/user/favorite/planets/': ROUTE.FAVORITE_PLANET_LIST,
  '/user/favorite/stars/': ROUTE.FAVORITE_STAR_LIST,
  '/user/inventory/apostles/': ROUTE.INVENTORY_APOSTLE_LIST,
  '/user/inventory/godiverses/': ROUTE.INVENTORY_GODIVERSE_LIST,
  '/user/inventory/orbiting/': ROUTE.INVENTORY_ORBITAL_TRACK_ORBITING,
  '/user/inventory/rental/': ROUTE.INVENTORY_ORBITAL_TRACK_RENTAL,
  '/user/inventory/planets/': ROUTE.INVENTORY_PLANET_LIST,
  '/user/inventory/stars/': ROUTE.INVENTORY_STAR_LIST,
  '/user/reward/dailyCheckIn/': ROUTE.REWARD_DAILY_CHECK_IN
}
